export default [
  {
    label: "Sunday",
    value: "sun"
  },
  {
      label: "Monday",
      value: "mon"
  },
  {
      label: "Tuesday",
      value: "tues"
  },
  {
      label: "Wednesday",
      value: "wed"
  },
  {
      label: "Thursday",
      value: "thurs"
  },
  {
      label: "Friday",
      value: "fri"
  },
  {
      label: "Saturday",
      value: "sat"
  }
];
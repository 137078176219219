export function OnlineOrderTrackingStatus({
    status
}) {
    return (
        <>
            {
                status === "pending" &&
                <>Your order is created.</>
            }
            {
                status === "incoming" &&
                <>Your order is recieved.</>
            }
            {
                status === "progress" &&
                <>Your order is being worked on.</>
            }
            {
                status === "ready" &&
                <>Your order is ready to pick up.</>
            }
            {
                status === "fulfilled" &&
                <>Your order is fulfilled.</>
            }
            {
                status === "cancelled" &&
                <>Your order is cancelled.</>
            }
        </>
    )
}

export default function OnlineOrderTrackingHeader(props) {
    let {
        order,
        onClick
    } = props;

    return (
        <div 
            className={ `${ order.status === "cancelled" ? "bg-rose-600 hover:bg-rose-700" : "bg-green-600 hover:bg-green-700" } transition-colors text-white py-6 cursor-pointer` }
            onClick={ onClick }>
            <div className="px-4 lg:px-24">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div>
                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" strokeWidth="2">
                                    <animate attributeName="r" from="8" to="20" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                                    <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                                </circle>
                                <circle cx="20" cy="20" fill="#fff" r="10"/>
                            </svg>
                        </div>
                        <div className="ml-6">
                            <div className="text-white text-lg sm:text-2xl font-bold">
                                Order #{ order.number }: <OnlineOrderTrackingStatus status={ order.status } />
                            </div>
                            <div>
                                Click here to view order details
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
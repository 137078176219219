import Media from "honeyjar/Media";

export default function OnlineOrderHeader(props) {
    let {
        title,
        image
    } = props;

    return (
        <div className="h-[150px] bg-slate-400 mb-10">
            {
                image &&
                <Media
                    { ...image }
                    alt={ title }
                    width={ 768 }
                    className="w-full h-full object-cover object-center pointer-events-none" />
            }
        </div>
    )
}
import {
    useCallback,
    useEffect,
    useState,
    useRef
} from "react";

import { createPortal } from 'react-dom';

import IconButton from "honeyjar/IconButton";
import AppLoading from "components/core/AppLoading";

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

export default function Dialog(props) {
    let {
        title,
        onClose,
        loading,
        noPadding=false,
        showClose=true,
        fullWidth=false,
        maxWidth=true,
        overlayClose=false,
        showTitleOnScroll=false
    } = props;

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    useEffect(() => {
        const closeDialog = (event) => {
            if(event.key === "Escape" && onClose) {
                onClose();

                event.preventDefault(); 
                return false;
            }
            
        }
        document.addEventListener("keydown", closeDialog);

        return () => {
            document.removeEventListener("keydown", closeDialog);
        }
    }, [
        onClose
    ])

    const [ showTitle, setShowTitle ] = useState(!showTitleOnScroll)

    useEffect(() => {
        document.body.style.overflow = "hidden";

        setIsLoading(false);

        let isSubscribed = true;

        setTimeout(() => {
            if(isSubscribed) {
                setDialogStyle({})
            }
        }, 100)

        return () => {
            document.body.style.overflow = "unset";
            isSubscribed = false
        }
    }, []);

    const [ dialogStyle, setDialogStyle ] = useState(fullWidth ? {
        transform: 'translateY(100%)',
        opacity: '0.4'
    }: {
        transform: 'translateY(-40px)',
        opacity: '0'
    });

    const contentRef = useRef(null);
    useEffect(() => {
        if(showTitleOnScroll && contentRef?.current) {
            const element = contentRef.current;

            const handleScroll = () => {
                const scrollPosition = element.scrollTop;
                if(scrollPosition > 300) {
                    setShowTitle(true);
                } else {
                    setShowTitle(false)
                }
            };
    
            element.addEventListener('scroll', handleScroll);
    
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [
        contentRef.current
    ])


    if(isLoading) {
        return null;
    }

    let dialogClass = "bg-white rounded relative z-20 transition flex flex-col"
    if(fullWidth) {
        // dialogClass += " w-[90vw] h-[85vh]"
        dialogClass += " w-full h-full md:w-auto md:h-auto md:max-h-[85vh] sm:min-w-[500px] lg:min-w-[600px] sm:min-h-[100px] duration-300"
    } else {
        dialogClass += " min-w-[300px] lg:min-w-[600px] min-h-[100px] duration-500 max-w-[90vw] max-h-[90vh]"
    }

    if(props.dialogClass) {
        dialogClass += " " + props.dialogClass;
    }

    return createPortal((
        <div className="fixed inset-0 bg-black/30 z-[100] flex items-center justify-center">
            <div className={ dialogClass } style={ dialogStyle }>
                {
                    title &&
                    <div
                        style={{ flex: '0 0 auto' }}
                        className={ 
                            `px-6 py-4 flex items-center justify-between border-b border-slate-200
                                ${ showTitleOnScroll ? 'absolute w-full bg-white' : '' }
                                ${ showTitle ? '' : 'hidden' }
                                ${ noPadding ? "" : "" }
                            ` 
                        }>
                        <div className="text-slate-900 text-xl font-bold w-full">
                            { title }
                        </div>
                        {
                            showClose && 
                            <IconButton title="Close" onClick={ onClose }>
                                <Icon path={mdiClose} size={1} />
                            </IconButton>
                        }
                        
                    </div>
                }
                {
                    showTitleOnScroll &&
                    <div className="absolute right-0 top-0 mt-4 mr-8">
                        <IconButton title="Close" onClick={ onClose } className="bg-white" style={{ boxShadow: "0px 0px 8px rgba(0,0,0,.16)" }}>
                            <Icon path={mdiClose} size={1} />
                        </IconButton>
                    </div>
                }
                {
                    props.children &&
                    <div 
                        style={{ flex: '1 1 auto' }}
                        className={ `
                            ${ noPadding ? "" : "px-6 py-6" } 
                            overflow-y-auto overflow-x-hidden flex-1` 
                        }
                        ref={ contentRef }>
                        { props.children }
                    </div>
                }
                {
                    props.footer &&
                    <div style={{ flex: '0 0 auto' }} className="px-6 py-3 flex items-center justify-between border-t border-slate-200 w-full">
                        { props.footer }
                    </div>
                }
                {
                    loading &&
                    <AppLoading
                        message={ props.loadingMessage } />
                }
            </div>
            {
                overlayClose &&
                <div className="absolute inset-0 z-10 cursor-pointer" onClick={ onClose } />
            }
        </div>
    ), document.body)
}

/*

                            ${ props.footer ? 
                                ( fullWidth ? "max-h-[85vh] sm:max-h-[65vh]" : "max-h-[65vh]" )
                                : 
                                "max-h-[85vh] pb-6"  
                            } 
*/
import {
    useState,
    useCallback,
    useEffect
} from "react";

import Price from "components/Price";
import { RadioInput } from "honeyjar/Radio";
import TextareaInput from "honeyjar/TextareaInput";
import { CheckboxInput } from "honeyjar/Checkbox";
import Icon from '@mdi/react';
import { mdiAlert } from '@mdi/js';




export default function OnlineOrderProductOption(props) {
    let {
        option={},
        selectedChoice={},
        error={},
        onSelectChoice
    } = props;

    return (
        <div className="border-t-2 pt-4 product-option" data-id={ option.id } style={{ scrollMargin: 80 }}>
            <div className="flex items-center justify-between mb-2 px-4">
                <div className="font-bold text-xl">
                    { option.label }
                </div>
                {
                   option.type === "radio" &&
                    <div className={ `${error.isRequired ? "bg-red-200 text-red-700" : "bg-slate-200"} font-semibold rounded-full flex items-center px-3 py-1 text-sm` }>
                        {
                            error.isRequired &&
                            <Icon path={mdiAlert} size={ '1rem' } className="mr-1" />
                        }
                        Required
                    </div>
                }
            </div>
            {
                option.type === "text" &&
                <div className="px-4">
                    <TextareaInput
                        placeholder="Add a note (extra sauce, no onions, .etc)"
                        value={ selectedChoice }
                        onChange={ event => {
                            onSelectChoice({
                                value: event.target.value
                            })  
                        } } />
                </div>
            }
            {
                (option.type === "radio" || option.type === "checkbox") && option?.choices?.length !== 0 &&
                <div>
                    {
                        option?.choices?.map((choice, index) => {
                            let checked = false;

                            if(selectedChoice.id === option.id) {
                                checked = Boolean(selectedChoice?.choices?.find(_choice => _choice.label === choice.label));
                            }

                            return (
                                <div
                                    key={ index }
                                    onClick={ () => onSelectChoice(choice) } 
                                    className="cursor-pointer border-b p-4 flex items-center justify-between hover:bg-slate-100 transition-colors">
                                    <div>
                                        <div className="font-semibold">
                                            { choice.label }
                                        </div>
                                        {
                                            parseFloat(choice.price) > 0 &&
                                            <div>
                                                +<Price
                                                    className="text-slate-700 text-sm"
                                                    price={ choice.price } />
                                            </div>
                                        }
                                    </div>
                                
                                    <div className="pointer-events-none">
                                        {
                                            option.type === "checkbox"
                                            ?
                                            <CheckboxInput 
                                                readOnly
                                                checked={ checked } />
                                            :
                                            <RadioInput 
                                                readOnly
                                                checked={ checked } />
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}
import {
    useState,
    useContext,
    useEffect
  } from "react";
  
  import Dialog from "honeyjar/Dialog";
  import formatTime from "utils/formatTime";
  import WEEKDAYS from "enum/WEEKDAYS";
  import Icon from '@mdi/react';
  import { mdiCheckCircle } from '@mdi/js';
  import OnlineOrderStoreMap from "./OnlineOrderStoreMap";
import SiteContext from "context/SiteContext";
import LocationAddress from "./LocationAddress";
import { getDirectionsFromLocation } from "utils/google/maps";
  
  interface Props {
    location: {
      hours: {
        day: number,
        open: Date,
        close: Date
      }[]
    };
    currentDate: Date;
    onClose: () => void;
  }
  
  export default function OnlineOrderHoursDialog(props: Props) {
    const {
      location,
      currentDate,
      onClose
    } = props;

    const { site } = useContext(SiteContext);

    const [hours, setHours] = useState<(typeof location.hours[number])[]>([]);
  
    useEffect(() => {
      if (location?.hours) {
        const newHours = WEEKDAYS.map(() => []);
        location.hours.forEach(hour => {
          newHours[hour.day].push(hour);
        });
        setHours(newHours);
      }
    }, [location]);

    return (
      <Dialog title="Hours & Map" onClose={ onClose } fullWidth={ true }>
        <div>
          <div>
            <OnlineOrderStoreMap
              location={ location } />
          </div>
          <div className="mt-5 px-5">
            <div className="font-bold">
              { site.name }
            </div>
            <div className="text-sm text-slate-700">
              <div>
                <LocationAddress
                  location={ location } />
              </div>
              {
                location.phone &&
                <div>
                  { location.phone }
                </div>
              }
            </div>
            <a 
              href={ getDirectionsFromLocation(location) } 
              target="_blank" 
              className="text-sm mt-4 inline-block underline text-slate-700">
              Get Directions
            </a>
          </div>

          <div className="mt-5 px-5">
            <div className="font-bold mb-4">
              Business Hours
            </div>
            <div className="text-sm">
              {
                WEEKDAYS.map((day, index) => {
                  const storeHours = hours[index];
                  const formatTimes = storeHours?.length
                    ? storeHours.map(storeHour => `${formatTime(storeHour.open)} - ${formatTime(storeHour.close)}`)
                    : ['Closed'];
                  return (
                    <div key={index} className="mb-2 border-b pb-2 last:border-0">
                      <div className="flex justify-between">
                        <div className="text flex items-center">
                          { day.label }
                          {index === currentDate.getDay() && <Icon path={mdiCheckCircle} size={0.6} className="text-green-700 ml-1" />}
                        </div>
                        <div className="text-slate-700">
                          {
                            formatTimes.map((time, timeIndex) => (
                              <div key={timeIndex}>
                                {time}
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
                }
            </div>
            
          </div>

        </div>
      </Dialog>
    )
}
  
import Icon from '@mdi/react';
import { mdiCartOutline, mdiMapMarkerOutline, mdiStoreClock, mdiPhone, mdiInformationSlabBoxOutline  } from '@mdi/js';
import LocationAddress from "./LocationAddress";
import { getMapsURLFromLocation } from "utils/google/maps"

import {
    getHoursLabel
} from "utils/time";

export default function OnlineOrderStoreInfo(props) {
    let {
        site,
        location,
        currentDate,
        onClickMoreInfo
    } = props;


    const day = currentDate.getDay();
    const currentDay = location?.hours?.filter(item => item.day === day);
    const hours = getHoursLabel(
        currentDay
    ) || "Closed";

    return (
        <div>
            <div className="text-3xl font-bold mb-2">
                { site.name } { site?.properties?.altName }
            </div>
            {
                location?.id &&
                <> 
                    <div>
                        <a href={ getMapsURLFromLocation(location) } target="_blank" className="inline-flex items-center mt-1">
                            <Icon path={mdiMapMarkerOutline} size={1} className="mr-2" />
                            <LocationAddress
                                location={ location } />
                        </a>
                    </div>
                    <div className="inline-flex items-center mt-1 cursor-pointer" onClick={ props.onClickMoreInfo }>
                        <Icon path={ mdiStoreClock } size={1} className="mr-2" />
                        Hours: { hours }
                    </div>
                    <div>
                        <button className="underline inline-flex items-center mt-1" onClick={ props.onClickMoreInfo }>
                            <Icon path={ mdiInformationSlabBoxOutline } size={1} className="mr-2" />
                            More Info
                        </button>
                    </div>
                </>
            }
        </div>
    )
}



/*
<div>
                <a href={ getMapsURLFromLocation(location) } target="_blank" className="inline-flex items-center mt-1">
                    <Icon path={mdiMapMarkerOutline} size={1} className="mr-2" />
                    <LocationAddress
                        location={ location } />
                </a>
            </div>
            {
                location?.phone && 
                <div>
                    <a href={ `tel:${location?.phone}` } className="inline-flex items-center mt-1">
                        <Icon path={mdiPhone} size={1} className="mr-2" />
                        { location.phone }
                    </a>
                </div>
            }
            
            <div className="inline-flex items-center mt-1 cursor-pointer" onClick={ props.onClickShowHours }>
                <Icon path={ mdiStoreClock } size={1} className="mr-2" />
                Hours: { hours }<span className="ml-1 underline">View Hours</span>
            </div>
*/
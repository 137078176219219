import {
    useState,
    useEffect
} from "react";


export const buildImageSrc = (props) => {
    let {
        src,
        width,
        provider
    } = props;
  
    if(provider === "cloudinary") {
        return `https://res.cloudinary.com/dbl2hlopv/image/upload/w_${width},f_auto/${src}`;
    } else if(provider === "external") {
        return src;
    }
  
    // if it is unsplash
    return `https://images.unsplash.com/photo-${src}?q=75&w=${width}&fm=webp`;
}

export default function Media(props) {
    let {
        provider,
        src,
        alt="",
        width=320
    } = props;

    const [ mediaSrc, setMediaSrc ] = useState("");

    useEffect(() => {
        let mediaSrc = buildImageSrc({
            src,
            provider,
            width
        })

        setMediaSrc(mediaSrc);
    }, [
        provider,
        src,
        width
    ])

    return (
        <img
            src={ mediaSrc }
            draggable={ false }
            alt={ alt }
            className={ props.className } />
    )
}
/*
    - Google Maps URL Link
    - Show Opening Hour
    - Hours. Checkmark Icon on current day. Need to check store time and day.

    - Review Cart
        - Show the items

    - Review Order
        - Address
        - Time
        - Payment
        - Checkout

    - Online Order shold contain order the stuct.
        - remember the time, and cusotmer info
*/

import {
    useState,
    useContext,
    useEffect,
    useCallback
} from "react";

import Dialog from "honeyjar/Dialog";
import TextareaInput from "honeyjar/TextareaInput";
import Icon from '@mdi/react';
import { mdiStoreMarker, mdiCalendarClock, mdiArrowLeft, mdiCashRegister  } from '@mdi/js';
import Button from "honeyjar/Button";
import Price from "components/Price";
import SiteContext from "context/SiteContext";
import { getMapsURLFromLocation } from "utils/google/maps";
import LocationAddress from "components/OnlineOrder/LocationAddress";
import useCartTotal from "components/OnlineOrder/useCartTotal";
import FormDialog from "honeyjar/FormDialog";
import OnlineOrderScheduleDialog from "components/OnlineOrder/OnlineOrderScheduleDialog";
import TextInput from "honeyjar/TextInput";
import IOrder from "@api/types/IOrder";
import IconButton from "honeyjar/IconButton";
import OnlineOrderPickupDetails from "components/OnlineOrder/OnlineOrderPickupDetails";
import OnlineOrderItemDetails from "components/OnlineOrder/OnlineOrderItemDetails";
import { OnlineOrderCartLines } from "components/OnlineOrder/OnlineOrderCart";
import OnlineOrderCartTotals from "components/OnlineOrder/OnlineOrderCartTotals";
import buildOrder from "components/OnlineOrder/buildOrderFromCart";
import isValidPhone from "utils/isValidPhone";

export default function OnlineOrderCheckoutDialog(props) {
    const {
        lines,
        location,
        cart,
        onCheckout,
        onClickLine,
    } = props;

    const { site } = useContext(SiteContext);

    const [ timeOption, setTimeOption ] = useState("standard");
    const [ screen, setScreen ] = useState("cart");
    const [ loading, setLoading ] = useState(false);
    const [ errors, setErrors ] = useState([]);

    const {
        total,
        taxTotal,
        subtotal
    } = useCartTotal(lines);

    const handleOnBackClick = useCallback(() => {
        if(screen === "customer") {
            setScreen("cart")
        } else if(screen === "payment") {
            setScreen("customer")
        }
    }, [ screen ]);
    
    const handleOnNextClick = useCallback(async () => {
        if(screen === "cart") {
            setScreen("customer");
        } else if(screen === "customer") {

            let {
                name,
                phone
            } = cart?.customer

            let errors = [];

            if(!String(name).trim()) {
                errors.push({
                    name: "name",
                    message: "Please enter a valid name"
                })
            }

            if(!String(phone).trim() || !isValidPhone(phone)) {
                errors.push({
                    name: "phone",
                    message: "Please enter a valid phone"
                })
            }

            setErrors(errors);

            if(!errors.length) {
                setScreen("payment");
            }
        } else if(screen === "payment") {
            setLoading(true);

            await onCheckout({
                total,
                taxTotal,
                subtotal
            });

            setLoading(false)
        }
    }, [
        screen,
        cart
    ])

    return (
        <>
            <Dialog
                fullWidth={ true }
                maxWidth={ false }
                title={
                    <div className="flex items-center">
                        {
                            (screen === "customer" || screen === "payment") &&
                            <IconButton className="mr-3" onClick={ handleOnBackClick }> 
                                <Icon path={mdiArrowLeft} size={1} />
                            </IconButton>
                        }

                        {
                            screen === "cart" &&
                            <>Cart - { lines.length } items</>
                        }

                        {
                            screen === "customer" &&
                            <>Customer Info</>
                        }

                        {
                            screen === "payment" &&
                            <>Checkout</>
                        }
                    </div>
                }
                noPadding
                onClose={ props.onClose }
                loading={ loading }
                loadingMessage="Placing Order..."
                footer={ (
                    <>
                    {
                        screen === "payment"
                        ?
                        <div className="w-full">
                            <div className="text-center mb-2 font-bold">
                                Total: <Price price={ total } />
                            </div>
                            <Button 
                                color="green" 
                                className="w-full text-lg font-bold py-3 rounded-lg"
                                onClick={ handleOnNextClick }>
                                    Place Order • Pay In Store
                            </Button>
                        </div>
                        :
                        <div className="w-full">
                            <Button 
                                color="green" 
                                className="w-full text-lg font-bold py-3 rounded-lg"
                                onClick={ handleOnNextClick }>
                                    {
                                        (screen === "cart" || screen === "customer")
                                        ?
                                        <>
                                            Continue
                                        </>
                                        :
                                        <>
                                            Place Order • <Price price={ total } />
                                        </>
                                    }
                            </Button>
                        </div>
                    }
                    </>
                ) }>

                    {
                        screen === "cart" &&
                        <div>
                            <OnlineOrderCartLines
                                lines={ lines }
                                onClickLine={ onClickLine } />
                        </div>
                    }

                    {
                        screen === "customer" &&
                        <div>
                            <div className="px-6 mb-12 mt-4">
                                <div>
                                    <TextInput
                                        label="Name"
                                        required
                                        name="name"
                                        autoFocus
                                        placeholder="Enter your name here"
                                        value={ cart?.customer?.name }
                                        errors={ errors }
                                        max="100"
                                        onChange={ event => {
                                            props.setCart({
                                                ...cart,
                                                customer: {
                                                    ...cart.customer,
                                                    name: event.target.value
                                                }
                                            })
                                        } } />
                                        
                                    <TextInput
                                        label="Phone"
                                        placeholder="Enter your phone here"
                                        name="phone"
                                        type="tel"
                                        required
                                        errors={ errors }
                                        max="50"
                                        value={ cart?.customer?.phone }
                                        onChange={ event => {
                                            props.setCart({
                                                ...cart,
                                                customer: {
                                                    ...cart.customer,
                                                    phone: event.target.value
                                                }
                                            })
                                        } } />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        screen === "payment" &&
                        <div className="my-4">
                            <div className="px-6">
                                <OnlineOrderPickupDetails
                                    customer={ cart.customer }
                                    location={ location } />

                                <div className="mt-2">
                                    <div
                                        className={ `flex items-center border-2 mb-2 px-4 py-3 cursor-pointer rounded min-h-[60px]` }>
                                        <div className="mr-4">
                                            <Icon path={ mdiCashRegister } size={1} />
                                        </div>
                                        <div>
                                            <div>
                                                Pay in Store
                                            </div>
                                            {
                                                site?.properties?.payments?.[0]?.instructions &&
                                                <div className="text-sm text-slate-600">
                                                    { site?.properties?.payments?.[0]?.instructions }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="px-6 mb-12 mt-8">
                                <div className="text-xl font-bold mb-4">
                                    Your Items
                                </div>
                                <div className="border-2 px-4 py-3 rounded">
                                    <OnlineOrderItemDetails
                                        lines={ lines }/>
                                    
                                    <OnlineOrderCartTotals
                                        total={ total }
                                        taxTotal={ taxTotal }
                                        subtotal={ subtotal } />
                                </div>
                            </div>

                            
                        </div>
                    }
            </Dialog>
        </>
    )
}


/*

                            {
                                false &&
                                <div className="px-4">
                                    <div className="text-xl font-bold mb-4">
                                        Add Note
                                    </div>
                                    <TextareaInput />
                                </div>
                            }
                            
<div className="px-4 mb-4">
                                {
                                    process.env.GOOGLE_MAPS_API_KEY && process.env.NODE_ENV !== "development" &&
                                    <iframe 
                                        name={ `${ site.name } location` }
                                        loading="lazy" 
                                        className="w-full h-[150px]"
                                        src={ `https://www.google.com/maps/embed/v1/place?key=${ process.env.GOOGLE_MAPS_API_KEY }&q=` + (`${location.address},${location.city},${location.state},${location.country}`) }
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                }
                            </div>

<div className="px-4 mt-4">
                                <div className="text-xl font-bold mb-4">
                                    Pick-up Time
                                </div>
                                <div
                                    onClick={ () => setTimeOption("standard") }
                                    className={ `flex items-center border-2 mb-2 px-4 py-3 cursor-pointer rounded ${ timeOption === "standard" ? "border-primary" : "" }` }>
                                    <div className="mr-4">
                                        <Icon path={ mdiMoped } size={1} />
                                    </div>
                                    <div>
                                        <div>
                                            As Soon As Possible
                                        </div>
                                        <div className="text-sm text-slate-600">
                                            5-15 min(s)
                                        </div>
                                    </div>
                                </div>
                                {
                                    false &&
                                    <div 
                                        onClick={ () => setTimeOption("schedule") }
                                        className={ `flex items-center border-2 px-4 py-3 cursor-pointer rounded ${ timeOption === "schedule" ? "border-primary" : "" }` }>
                                        <div className="mr-4">
                                            <Icon path={ mdiCalendarClock } size={1} />
                                        </div>
                                        <div>
                                            <div>
                                                Schedule
                                            </div>
                                            <div className="text-sm text-slate-600">
                                                Select a time
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
*/
import {
    useContext,
    useState,
    useEffect,
} from "react";

import SiteContext from "context/SiteContext";

import getPlanFeatureValue from "plan/PlanService";

export default function useIsOnlineOrderSetupDone({
    location
  }) {
  
    const { site } = useContext(SiteContext);
    const [ isSetupDone, setIsSetupDone ] = useState(false);
  
    useEffect(() => {
      let isSetupDone = true;

      // !getPlanFeatureValue(site.planId, "hasOnlineOrder")

      if(
        !site?.properties?.timezone ||
        !site?.properties?.collection_ids?.length || 
        !site?.properties?.timezone || 
        typeof site?.properties?.taxRate === "undefined" || 
        !site?.properties?.payments?.length ||
        !location?.id
      ) {
        isSetupDone = false;
      }
  
      setIsSetupDone(isSetupDone);
    }, [
      site,
      location
    ])
  
    return isSetupDone;
}
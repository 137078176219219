import {
    useContext,
    useEffect,
    useState
} from "react";

import SiteContext from "context/SiteContext";

export default function useCartTotal(lines) {
    const { site } = useContext(SiteContext);

    const [ subtotal, setSubtotal ] = useState(0);
    const [ taxTotal, setTaxTotal ] = useState(0);
    const [ total, setTotal ] = useState(0);

    useEffect(() => {
        let subtotal = 0;
        let taxTotal = 0;
        let total = 0;

        lines?.forEach((line) => {
            subtotal += Number(line?.product?.price) ?? 0;

            line?.options?.forEach(option => {
                option?.choices?.forEach(choice => {
                    subtotal += Number(choice.price);
                })
            })
        });

        const taxRate = site?.properties?.taxRate;
        if(taxRate > 0) {
            taxTotal = (subtotal * taxRate) / 100;
        }
        
        /*
        taxes.forEach((tax) => {
            const taxAmount = (subtotal * tax.rate) / 100;
            taxTotal += taxAmount;
        });
        */

        function roundToTwo(num) {
            return +(Math.round(num + 'e+2') + 'e-2');
        }

        // Round the numbers to two decimal places
        subtotal = roundToTwo(subtotal);
        taxTotal = roundToTwo(taxTotal);
        total = roundToTwo(subtotal + taxTotal);

        

        setSubtotal(subtotal);
        setTaxTotal(taxTotal);
        setTotal(total);
    }, [
        lines
    ])

    return {
        total,
        subtotal,
        taxTotal
    }
} 
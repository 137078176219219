import {
    useState,
    useEffect
} from "react";

import FormLabel from "honeyjar/FormLabel"

const Input = ({
    error=false,
    ...props
}) => {
    return (
        <textarea
            { ...props }
            className={`resize-none py-1.5 px-2.5 border w-full rounded ${ error ? "border-red-600" : "border-slate-400" } ${ props.className || "" }`}/>
    )
}

export default function TextareaInput({
    label,
    errors,
    helperMsg,
    ...props
}: {
    label: any,
    errors?: object[],
    className?: string,
    placeholder?: string,
    name: string,
    value: any,
    type?:string,
    onChange: any,
    helperMsg?:any
}) {

    const [ errorMsg, setErrorMsg ] = useState("");
    useEffect(() => {
        if(errors?.length && props.name) {
            let error = errors.find(err => err.name === props.name);
            if(error) {
                setErrorMsg(error.message)
            } else {
                setErrorMsg("")
            }
        } else {
            setErrorMsg("")
        }
    }, [ errors, props.name ])

    return (
        <div className="mb-4">
            {
                label &&
                <div className="mb-2">
                    <FormLabel>{label}</FormLabel>
                </div>
            }
            
            <div>
                <Input
                    error={ !!errorMsg }
                    { ...props } />
            </div>

            {
                errorMsg &&
                <div className="text-sm text-red-600">
                    { errorMsg }
                </div>
            }

            {
                helperMsg &&
                <div className="text-sm text-slate-600">
                    { helperMsg }
                </div>
            }
        </div>
    )
}
export default function generateOrderNumber() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let word = '';
  
    for (let i = 0; i < 2; i++) {
      word += letters.charAt(Math.floor(Math.random() * letters.length));
    }
  
    for (let i = 0; i < 3; i++) {
      word += Math.floor(Math.random() * 10);
    }
  
    return word;
}
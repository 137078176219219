import {
    useState,
    useCallback,
    useEffect
} from "react";

import Price from "components/Price";
import Button from "honeyjar/Button";
import Dialog from "honeyjar/Dialog";
import OnlineOrderProductOption from "./OnlineOrderProductOption";

import IProduct from "@api/types/IProduct"
import ILine from "@api/types/ILine"
import Media from "honeyjar/Media";
import { v4 as uuidv4 } from 'uuid';

export interface IOnlineOrderProductDialog {
    product: IProduct,
    onClose: () => {},
    line: ILine,
    onAdd: (choices) => {},
    isEdit?: boolean
}

export default function OnlineOrderProductDialog(props : IOnlineOrderProductDialog) {
    let {
        product,
        onClose,
        line,
        onAdd,
        isEdit=false
    } = props;

    const [ selectedOptions, setSelectedOptions ] = useState(line?.options || []);
 
    const [ selectedChoices, setSelectedChoices ] = useState(line?.options || {});
    const [ finalPrice, setFinalPrice ] = useState<number>(product.price || 0);
    const [ errors, setErrors ] = useState([])
    
    useEffect(() => {
        let finalPrice = Number(product.price);

        selectedOptions?.forEach(option => {
            option?.choices?.map(choice => {
                finalPrice += Number((choice?.price || 0));
            })
        })

        setFinalPrice(finalPrice);
    }, [
        product,
        selectedOptions
    ]);

    const handleOnAdd = useCallback(async () => {
        let errors = [];

        product?.options?.forEach(option => {
            if(option.required || option.type === "radio") {
                let selectedOption = selectedOptions.find(opt => opt.id === option.id);

                if(!selectedOption?.choices?.length) {
                    errors.push({
                        id: option.id,
                        isRequired: true
                    })
                }
            }
        });
        setErrors(errors);

        if(errors.length) {
            document.querySelector(`.product-option[data-id="${ errors[0]?.id }"]`)?.scrollIntoView()
        } else {
            let newLine = {
                "id": line?.id || uuidv4(),
                "added_at": new Date().toISOString(),
                "product": {
                    "id": product.id,
                    "name": product.name,
                    "price": product.price
                },
                "options": selectedOptions,
                "total": finalPrice
            }

            if(isEdit) {
                props.onEdit(newLine);
            } else {
                onAdd(newLine);
            }
        }
    }, [
        selectedOptions,
        onAdd,
        product,
        finalPrice,
        line
    ]);

    const onSelectChoice = useCallback((option, choice) => {
        let choices = [];
        if(option.type === "radio") {
            choices = [ choice ]
        } else if(option.type === "checkbox") {
            choices = [
                ...(selectedOptions?.find(_option => _option.id === option.id)?.choices || [])
            ]

            if(choices.find(_choice => _choice.label === choice.label)) {
                choices = choices.filter(_choice => _choice.label !== choice.label);
            } else {
                choices.push(choice)
            }
        }

        let selectedOption = {
            id: option.id,
            label: option.label,
            type: option.type,
            choices
        };
        
        let _errors = errors.filter(error => error.id !== option.id);
        setErrors(_errors);

        let isAdd = true;
        let _options = selectedOptions.map(option => {
            if(option.id === selectedOption.id) {
                isAdd = false;

                return selectedOption;
            } else {
                return option;
            }
        })

        if(isAdd) {
            _options.push(selectedOption);
        }

        setSelectedOptions(_options)
    }, [
        selectedOptions,
        errors
    ])

    return (
        <Dialog
            noPadding
            dialogClass="max-w-[600px]"
            overlayClose={ true }
            fullWidth={ true }
            onClose={ onClose }
            title={ product.name }
            showTitleOnScroll={ true }
            footer={ (
                <div className="w-full">
                    <Button 
                        className="w-full text-lg py-3 font-semibold rounded-full" 
                        color="green"
                        onClick={ handleOnAdd }>
                            {
                                isEdit ? "Update Item " : "Add to Order "
                            }
                            • <Price price={ finalPrice } />
                    </Button>
                </div>
            ) }>
            <div>
                {
                    false
                    ?
                    <div className="aspect-video">
                        {
                            false &&
                            <Media
                                src="1617422725360-45b7671f980b"
                                alt={ product.name }
                                width="600"
                                className="w-full object-cover object-center aspect-video pointer-events-none" />
                        }
                    </div>
                    :
                    <div className="h-[60px]">
                    </div>
                }
                
                <div className="my-4 px-4">
                    <div className="flex items-center justify-between">
                        <div className="text-2xl font-bold">
                            { product.name }
                        </div>
                    </div>
                    {
                        product.desc ?
                        <div className="mt-2">
                            { product.desc }
                        </div>
                        :
                        <div className="my-6"></div>
                    }
                </div>
                {
                    product?.options?.length !== 0 &&
                    <div>
                        {
                            product?.options?.map((option, index) => {
                                if(!option.label || !option?.choices?.length) {
                                    return null;
                                }

                                return (
                                    <OnlineOrderProductOption 
                                        key={ index }
                                        option={ option }
                                        selectedChoice={ selectedOptions?.find(opt => opt.id === option.id) }
                                        error={ errors.find(error => error?.id === option?.id) }
                                        onSelectChoice={ choice => {
                                            onSelectChoice(option, choice);
                                        } } 
                                    />
                                )
                             })
                        }
                    </div>
                }

                {
                    isEdit &&
                    <div 
                        onClick={ props.onDelete }
                        className="border-t-4 py-6 font-bold text-rose-700 hover:bg-slate-100 transition-colors text-center cursor-pointer">
                        Remove From Cart
                    </div>
                }

                {
                    /*
<OnlineOrderProductOption 
                    option={ {
                        label: "Special Instructions",
                        type: "text"
                    } }
                    selectedChoice={ selectedChoices?.["_note"] || "" }
                    selectChoice={ choice => {
                        setSelectedChoices({
                            ...selectedChoices,
                            _note: choice.value
                        });
                    } } />
                    */
                }

                
            </div>
        </Dialog>
    )
}
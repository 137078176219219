export function getMapsURLFromLocation(location) {
    if (location) {
      let {
        address,
        city,
        country,
        state
      } = location;
  
      const BASE_URL = 'https://www.google.com/maps/search/?api=1&query=';
  
      let encodedAddress = '';
  
      if (address) {
        encodedAddress += address;
      }
  
      if (city) {
        encodedAddress += (encodedAddress ? ',' : '') + city;
      }
  
      if (state) {
        encodedAddress += (encodedAddress ? ',' : '') + state
      }
  
      if (country) {
        encodedAddress += (encodedAddress ? ',' : '') + country
      }
  
      let url = encodedAddress ? `${BASE_URL}${encodeURIComponent(encodedAddress)}` : '';
  
      return url;
    }
}

export function getDirectionsFromLocation(location) {
  if (location) {
    let {
      address,
      city,
      country,
      state
    } = location;

    const BASE_URL = 'https://www.google.com/maps/search/?api=1&destination=';

    let encodedAddress = '';

    if (address) {
      encodedAddress += address;
    }

    if (city) {
      encodedAddress += (encodedAddress ? ',' : '') + city;
    }

    if (state) {
      encodedAddress += (encodedAddress ? ',' : '') + state
    }

    if (country) {
      encodedAddress += (encodedAddress ? ',' : '') + country
    }

    let url = encodedAddress ? `${BASE_URL}${encodeURIComponent(encodedAddress)}` : '';

    return url;
  }
}
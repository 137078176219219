import Price from "components/Price";
import anchorify from "utils/anchoify";
import Media from "honeyjar/Media";

export default function OnlineOrderMenu(props) {
    const {
        collections,
        products,
        onClickProduct
    } = props;

    return (
        <div>
            {
                collections.map((collection, index) => {
                    const productsInCollection = collection?.product_ids?.map(id => {
                        return products.find(product => product.id === id)
                    })

                    return (
                        <div 
                            key={ collection.id } 
                            className="mb-14 category">
                            <a title={ anchorify(collection.name) } style={{ scrollMargin: 100 }}></a>

                            <div className="text-2xl font-bold mb-6">
                                { collection.name }
                            </div>

                            <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                                {
                                    productsInCollection.map((product, productIndex) => {

                                        if(!product) {
                                            return null;
                                        }

                                        return (
                                            <div
                                                key={ product.id }
                                                onClick={ () => { onClickProduct(product) } } 
                                                className="border bg-white cursor-pointer border-slate-300 p-6 rounded-lg hover:shadow-2xl transition-all">
                                                    <div className="flex">
                                                        {
                                                            product?.medias?.[0] &&
                                                            <div>
                                                                <Media
                                                                    { ...product?.medias?.[0] }
                                                                    className="min-w-[120px] h-[80px] object-contain mr-4" />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div className="font-bold">
                                                                { product.name }
                                                            </div>
                                                            <div>
                                                                <Price price={ product.price } />
                                                            </div>
                                                            {
                                                                product.desc &&
                                                                <div className="text-slate-700 text-sm">
                                                                    { product.desc.substring(0, 120) }
                                                                    { product.desc.length > 120 && "..." }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
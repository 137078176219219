export function formatTime(time) {
    const hour = Math.floor(Number(time) / 100);
    const minute = Number(time) % 100;
    const period = hour < 12 ? 'AM' : 'PM';
    const formattedHour = (hour === 0 || hour === 12) ? 12 : hour % 12;
    return `${formattedHour}:${minute.toString().padStart(2, '0')}${period}`;
}

export function getHoursLabel(currentDay) {
    if (currentDay && currentDay.length > 0) {
      const formattedTimes = currentDay.map(item => {
        const openTime = formatTime(item.open);
        const closeTime = formatTime(item.close);
        return `${openTime} - ${closeTime}`;
      });
      return formattedTimes.join('• ');
    }
    return '';
}
import { useCallback } from "react";
import anchorify from "utils/anchoify";

export default function OnlineOrderCategory(props) {
    let {
        collections
    } = props;

    const handleChange = useCallback((event) => {
        document.querySelector(`a[title="${ event?.target?.value }"]`)?.scrollIntoView()
    }, [])

    return (
        <select
            onChange={ handleChange }
            aria-label="Category"
            className="border border-slate-300 px-4 py-3 my-8 w-full text-lg bg-white rounded-lg">
                {
                    collections.map(collection => {
                        return (
                            <option 
                                key={ collection.id }
                                value={ anchorify(collection.name) }>
                                    { collection.name }
                            </option>
                        )
                    })
                }
        </select>
    )
}
export function logError(error, fatal:boolean=false) {
    if(typeof gtag !== "undefined" && error) {
        gtag?.('event', 'exception', {
            'description': error.toString(),
            value: error.stack,
            fatal
        });
    }
}

export function sendEvent(type, payload) {
    if(type) {
        window?.gtag?.('event', type, payload);
    }
}

export default function Analytics() {
    return (
        <>
            {
                process.env.GOOGLE_ANALYTICS_ID &&
                <>
                    <script async src={ `https://www.googletagmanager.com/gtag/js?id=${ process.env.GOOGLE_ANALYTICS_ID }` }></script>
                    <script dangerouslySetInnerHTML={{ __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                
                        gtag('config', '${ process.env.GOOGLE_ANALYTICS_ID }');
                    ` }} />
                </>
            }
          
        </>
      )
}
import {
    useCallback,
    useState
} from "react";

import MessageDialog from "honeyjar/MessageDialog";

export default function ErrorMessageDialog({
    children,
    ...props
}) {
    return (
        <MessageDialog
            title="Ooops"
            { ...props }>
            { children }
        </MessageDialog>
    )
}
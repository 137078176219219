import Price from "components/Price";
import React from "react";
import useCartTotal from "./useCartTotal";

export default function OnlineOrderCartTotals(props) {
    let {
        subtotal,
        taxTotal,
        total
    } = props;

    return (
        <div>
            <div className="px-2">
                <div className="flex justify-between pb-1 text-slate-700 text-sm">
                    <div>
                        Subtotal
                    </div>
                    <div>
                        <Price price={ subtotal } />
                    </div>
                </div>
                <div className="flex justify-between pb-1 text-slate-700 text-sm">
                    <div>
                        Taxes
                    </div>
                    <div>
                        <Price price={ taxTotal } />
                    </div>
                </div>
                <div className="flex justify-between font-bold">
                    <div>
                        Total
                    </div>
                    <div>
                        <Price price={ total } />
                    </div>
                </div>
            </div>
        </div>
    )
}
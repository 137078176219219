/*
    v2
        - revlidate of order
            - check if its yesterday ?? because there will be in issue.
        - check if restaurant is ready to go
        - cache the products
            - do a better check if product has options
                - so i dont have to do another ajax
*/

import {
    useEffect,
    useState,
    useContext,
    useCallback
} from "react";
import Icon from '@mdi/react';
import { mdiCartOutline } from '@mdi/js';
import APIProvider from "@api/APIProvider";
import OnlineOrderProductDialog from "components/OnlineOrder/OnlineOrderProductDialog";
import OnlineOrderCheckoutDialog from "components/OnlineOrderCheckoutDialog/OnlineOrderCheckoutDialog";
import Head from 'next/head'
import LocationAddress from "./LocationAddress";
import OnlineOrderHoursDialog from "./OnlineOrderHoursDialog";
import OnlineOrderCart  from "./OnlineOrderCart"
import { v4 as uuidv4 } from 'uuid';
import Button from "honeyjar/Button";

import ILine from "@api/types/ILine"
import ISite from "@api/types/ISite";
import ILocation from "@api/types/ILocation";
import ICollection from "@api/types/ICollection";
import IProduct from "@api/types/IProduct";
import Loading from "honeyjar/Loading";
import OnlineOrderHeader from "./OnlineOrderHeader";
import OnlineOrderStoreInfo from "./OnlineOrderStoreInfo";
import OnlineOrderMenu from "./OnlineOrderMenu";
import SiteContext from "context/SiteContext";
import OnlineOrderCategory from "./OnlineOrderCategory";
import Dialog from "honeyjar/Dialog";
import FormDialog from "honeyjar/FormDialog";
import Link from "next/link";
import OnlineOrderTrackingDialog from "./OnlineOrderTrackingDialog";
import OnlineOrderTrackingHeader from "./OnlineOrderTrackingHeader";
import IOrder from "@api/types/IOrder";
import LocalStorage from "@api/LocalStorage";
import generateOrderNumber from "utils/generateOrderNumber";
import useIsStoreOpen from "./useIsStoreOpen";
import OnlineOrderStoreClosedDialog from "./OnlineOrderStoreClosedDialog";
import useIsOnlineOrderSetupDone from "./useIsOnlineOrderSetupDone";
import MessageDialog from "honeyjar/MessageDialog";
import OnlineOrderStoreOfflineDialog from "./OnlineOrderStoreOfflineDialog";
import AppLogo from "components/core/AppLogo";
import { sendEvent } from "honeyjar/Analytics";

function OnlineOrderGlobalStyle() {
    return (
        <style dangerouslySetInnerHTML={{ __html: `
            body {
                background: #fff;
                color: #1f1f1f;
            }
        ` }} />
    )
}

let ORDER_CACHE;

export default function OnlineOrder() {
    const { site } = useContext(SiteContext);

    const [ location, setLocation ] = useState<ILocation>({});
    const [ products, setProducts ] = useState<IProduct[]>([])
    const [ collections, setCollections ] = useState<ICollection[]>([]);

    const [ loading, setLoading ] = useState<boolean>(true)

    const [ lines, setLines ] = useState<ILine[]>([]);

    const [ activeDialog, setActiveDialog ] = useState({});

    const [ currentDate, setCurrentDate ] = useState(null);
    const [ isClosed, setIsClosed ] = useState(false);

    const [ orderInfo, setOrderInfo ] = useState();

    const [ cart, setCart ] = useState({
        customer: {
            name: "",
            phone: ""
        }
    });

    const [ currentOrder, setCurrentOrder ] = useState({});

    const isStoreOpen = useIsStoreOpen(
        site?.properties?.timezone,
        location?.hours
    );

    const isSetupDone = useIsOnlineOrderSetupDone({
        location
    });

    const [ firstRun, setFirstRun ] = useState(false);
    useEffect(() => {
        if(!loading && !firstRun) {
            if(!isSetupDone) {
                setActiveDialog({
                    type: "offline"
                })
            } else if(!isStoreOpen) {
                setActiveDialog({
                    type: "closed"
                })
            }

            setFirstRun(true);
        }
    }, [
        loading
    ]);

    const handleOnCheckout = useCallback(async ({
        total,
        taxTotal,
        subtotal
    }) => {
        if(isStoreOpen) {
            if(lines.length) {
                try {
                    let number = generateOrderNumber();
                    // if(await APIProvider.checkOrderNumberExists(order.number, order.created_at)) {
                        // number = generateOrderNumber();
                    // }
    
                    let newLines = lines.map(line => {
                        let total = Number(line?.product?.price);
                        
                        line?.options?.forEach(option => {
                            option?.choices?.forEach(choice => {
                                total += Number(choice.price);
                            })
                        })
    
                        return {
                            ...line,
                            total
                        }
                    })

                    const createdAt = new Date();
                    // const scheduledAt = new Date()
                    // const dueAt = new Date(createdAt.getTime() + 15 * 60000);
    
                    let order:Partial<IOrder> = {
                        number,
                        site_id: site.id,
                        user_id: site.user_id,
                        location,
                        created_at: createdAt.toISOString(),
                        due_at: createdAt.toISOString(),
                        // location_id: location.id,
                        // customer_id,
                        lines: newLines,
                        customer: cart.customer,
                        total,
                        taxes: taxTotal,
                        subtotal: subtotal,
                        status: "pending"
                    }
    
                    let response = await APIProvider.createOrder(order);
        
                    order = {
                        ...order,
                        ...response
                    }
                    
                    ORDER_CACHE.set("orders", [ order ]);
        
                    setCurrentOrder(order);
                    await APIProvider.subscribeToOrder(order.id, (response) => {
                        setCurrentOrder({
                            ...currentOrder,
                            ...response
                        });
                    });
    
                    setActiveDialog({
                        type: "tracking"
                    })
    
                    setLines([]);
        
                    window.scroll(0,0);

                    sendEvent("checkout", {
                        domain: site.domain,
                        total
                    })
                } catch(e) {
                    // handle error when placing order
                }
            }
        } else {
            setActiveDialog({
                type: "closed"
            })
        }
    }, [
        lines,
        cart,
        currentOrder,
        location,
        isStoreOpen
    ]);

    useEffect(() => {
        const fetchData = async () => {
          let location = await APIProvider.getLocation(site.user_id);
          setLocation(location || {});

          let collections = await APIProvider.getCollections(site.user_id);
          if (collections) {
            let products = await APIProvider.getProducts(site.user_id);
      
            if (site?.properties?.collection_ids) {
                collections = collections.sort(
                (a, b) =>
                    site?.properties?.collection_ids.indexOf(a.id) -
                    site?.properties?.collection_ids.indexOf(b.id)
                );
            }
        
            setCollections(collections);
            setProducts(products);
          }
          
          // Get the current date and time with the specified timezone
            const formatter = new Intl.DateTimeFormat('en-US', {
                timeZone: site?.properties?.timezone || "America/Vancouver",
                hour12: false,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }).format(new Date());

            const currentDate = new Date(formatter);

            setCurrentDate(currentDate);

            if(!ORDER_CACHE) {
                ORDER_CACHE = new LocalStorage({
                    key: "opentummy-order"
                });    
            }
            
            let cacheOrder = ORDER_CACHE.get("orders")?.[0];

            if(cacheOrder) {
                let order = await APIProvider.getOrder({
                    siteId: site.id,
                    orderId: cacheOrder.id,
                    locationId: location.id
                });

                let removeCacheOrder = true;
                if(order) {
                    if(order.site_id === site.id && order?.location?.id === location.id) {
                        setCurrentOrder(order);

                        if(order.status === "cancelled" || order.status === "fulfilled") {

                        } else {
                            removeCacheOrder = false;
                            
                            await APIProvider.subscribeToOrder(order.id, (response) => {
                                setCurrentOrder(prevOrder => ({
                                    ...prevOrder,
                                    ...response
                                }));
                            });
                        }

                        /*
                        if(
                            (order.status === "cancelled" || order.status === "fulfilled")
                        ) {
                            const timeDifference = currentDate.getTime() - new Date(order.created_at).getTime();
                            const hoursDifference = timeDifference / (1000 * 60);

                            if (hoursDifference <= 2) {
                                removeCacheOrder = false;
                            }

                            setCurrentOrder(order);
                        } else {
                            removeCacheOrder = false;
                            setCurrentOrder(order);
    
                            await APIProvider.subscribeToOrder(order.id, (response) => {
                                setCurrentOrder(prevOrder => ({
                                    ...prevOrder,
                                    ...response
                                }));
                            });
                        }
                        */
                    }
                }

                console.log("rmove", removeCacheOrder)
               
                if(removeCacheOrder) {
                    ORDER_CACHE.unset("orders");
                }
            }

            setLoading(false);
        };
      
        fetchData();
    }, []);

    const handleShowCheckoutDialog = useCallback(() => {
        let type = "checkout"

        if(!isStoreOpen) {
            type = "closed";
        } else if(currentOrder?.id) {
            if(currentOrder.status === "cancelled") {
            } else if(currentOrder.status !== "fulfilled") {
                type = "currentOrder"
            }
        }
        
        setActiveDialog({
            type
        })
    }, [isStoreOpen, currentOrder])

    if(loading) {
        return (
            <div>
                <Head>
                    <title>Online Ordering — Powered by OpenTummy</title>
                </Head>
                <div className="mt-24">
                    <OnlineOrderGlobalStyle />
                    <Loading />
                </div>
            </div>
        )
    }

    return (
        <div>
            <Head>
                <title>{ site.name } { site?.properties?.altName } Online Ordering { location?.city ? `— ${ location.city }` : "" } — Powered by OpenTummy</title>
            </Head>

            <OnlineOrderGlobalStyle />
            {
                currentOrder?.id &&
                <OnlineOrderTrackingHeader 
                    order={ currentOrder }
                    onClick={() => {
                        setActiveDialog({
                            type: "tracking"
                        })
                    }}/>
            }

            <OnlineOrderHeader
                title={ site.name }
                image={ site?.properties?.image } />

                
            <div className="px-4 lg:px-24">
                <OnlineOrderStoreInfo
                    site={ site }
                    location={ location }
                    currentDate={ currentDate }
                    onClickMoreInfo={ () => {
                        setActiveDialog({ type: 'hours' })
                    } } />


                <div className="flex">
                    <div className="w-full sticky top-0">
                        {
                            collections?.length > 0 ?
                            <OnlineOrderCategory
                                collections={ collections } />
                            :
                            <div className="text-center pt-12 text-2xl">
                                Menu Coming Soon
                            </div>
                        }

                        <OnlineOrderMenu
                            collections={ collections }
                            products={ products }
                            onClickProduct={ async (product) => {
                                // todo(write a reducer for this)
                                if(!product.lines) {
                                    try {
                                        let response = await APIProvider.getProduct(product.id);

                                        product = {
                                            ...product,
                                            ...response
                                        }

                                        setProducts(prevProducts => {
                                            return prevProducts.map(_product => {
                                                if(_product.id === product.id) {
                                                    return {
                                                        ..._product,
                                                        ...product
                                                    }
                                                }
                    
                                                return _product;
                                            });
                                        })
                                    } catch(e) {

                                    }
                                }

                                setActiveDialog({
                                    type: 'productDialog',
                                    product: product
                                })
                            } } />
                    </div>
                    <div>
                        {
                            false && 
                            <div className="w-[360px] sticky top-[12px] ml-12 bg-white hidden lg:block text-center">
                                As Soon As Possible
                            </div>
                        }
                        

                        <OnlineOrderCart
                            lines={ lines }
                            onClickLine={ line => {
                                setActiveDialog({
                                    type: 'productDialog',
                                    product: products.find(product => product.id === line.product.id),
                                    line,
                                    isEdit: true
                                })
                            } }
                            onCheckout={ handleShowCheckoutDialog } />
                    </div>
                </div>

                <div className="text-center py-12 flex flex-col items-center">
                    <Link href="https://opentummy.ca" target="_blank" className="mb-4">
                        <AppLogo />
                    </Link>
                    <Link href="https://opentummy.ca" target="_blank" className="font-bold text-lg">
                        Powered by Open Tummy
                    </Link>
                </div>
            </div>

            {
                lines?.length >= 1 && !activeDialog.type &&
                <div className="sticky bottom-0 py-4 text-center px-4 flex lg:hidden bg-white border-t">
                    <Button 
                        color="green"
                        onClick={ handleShowCheckoutDialog }
                        className="w-full py-4 rounded-full  flex justify-center items-center">
                            <Icon path={mdiCartOutline} size={ 1} className="mr-1" />
                            { lines.length } Item{ lines.length > 1 ? "s" : "" }  in Order
                    </Button>
                </div>
            }

            {
                activeDialog.type === "productDialog" &&
                <OnlineOrderProductDialog
                    { ...activeDialog }
                    onAdd={ (line) => {  
                        setLines([ 
                            ...lines, 
                            line
                        ])
                        setActiveDialog({})
                    } }
                    onDelete={ () => {
                        let _lines = lines.filter(line => line.id !== activeDialog.line.id);

                        setLines(_lines)
                        setActiveDialog({})
                    } }
                    onEdit={ line => {
                        let _lines = lines.map(_line => {
                            if(_line.id === activeDialog.line.id) {
                                return line;
                            }

                            return _line;
                        });
                        
                        setLines(_lines);

                        setActiveDialog({})
                    } }
                    onClose={ () => setActiveDialog({}) } />
            }

            {
                activeDialog.type === "currentOrder" &&
                <MessageDialog title="Contact Store for Assistance" onClose={ () => setActiveDialog({}) }>
                    <div className="py-4">
                        You already have an ongoing order. To edit the order, please contact the store at <a className="underline" href={ location.phone } target="_blank">{ location.phone }</a>.
                    </div>
                </MessageDialog>
            }

            {
                activeDialog.type === "checkout" &&
                <OnlineOrderCheckoutDialog
                    lines={ lines }
                    cart={ cart }
                    setCart={ setCart }
                    onCartChange={ payload => setCart({ ...cart, ...payload }) }
                    location={ location }
                    onClose={ () => setActiveDialog({}) }
                    onClickLine={ line => {
                        setActiveDialog({
                            type: 'productDialog',
                            product: products.find(product => product.id === line.product.id),
                            line,
                            isEdit: true
                        })
                    } }
                    onCheckout={ handleOnCheckout } />
            }

            {
                activeDialog.type === "hours" &&
                <OnlineOrderHoursDialog
                    location={ location }
                    currentDate={ currentDate }
                    onClose={ () => setActiveDialog({}) } />
            }

            {
                isClosed &&
                <FormDialog 
                    title="Store Closed"
                    saveLabel="Schedule order"
                    cancelLabel="See store"
                    onSave={ () => {} }
                    onClose={ () => {} }>
                    <div>
                        This store is currently is closed right now, but you can schedule and order for later
                    </div>
                </FormDialog>
            }

            {
                activeDialog.type === "tracking" &&
                <OnlineOrderTrackingDialog
                    location={ location }
                    order={ currentOrder }
                    onClose={ () => setActiveDialog({}) } />
            }

            {
                activeDialog.type === "closed" &&
                <OnlineOrderStoreClosedDialog
                    onClose={ () => setActiveDialog({}) }
                    currentDate={ currentDate }
                    location={ location } />
            }

{
                activeDialog.type === "offline" &&
                <OnlineOrderStoreOfflineDialog
                    onClose={ () => setActiveDialog({}) }
                    currentDate={ currentDate }
                    location={ location } />
            }

            

            
            
        </div>
    )
}
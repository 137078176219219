export default function LocationAddress(props) {
    let {
        location={}
    } = props;

    return (
        <>
            { location?.address }, { location?.city }, { location?.state }, { location?.zip }
        </>
    )
}
import Dialog from "honeyjar/Dialog";
import { getHoursLabel } from "utils/time";
  
export default function OnlineOrderStoreClosedDialog(props) {
  let {
    onClose,
    currentDate,
    location
  } = props;

  const day = currentDate.getDay();
  const currentDay = location?.hours?.filter(item => item.day === day);
  const hours = getHoursLabel(
      currentDay
  );

  return (
    <Dialog
      title="Store Closed"
      onClose={ onClose }>
      <div>
        {
          hours
          ?
          <> The store is currently closed, today's hours are from <b>{ hours }</b></>
          :
          <> The store is currently closed today.</>
        }
      </div>
    </Dialog>
  )
}
  
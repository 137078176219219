export default function formatPrice(price) {
  let formattedPrice = "";

  price = parseFloat(price || 0);

  // check if NaN
  if(Intl?.NumberFormat) {
      formattedPrice = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(price)
  } else {
      formattedPrice = price.toLocaleString("en-US", {minimumFractionDigits: 2});
  }

  return formattedPrice;
}
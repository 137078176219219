import {
    useCallback,
    useState
} from "react";

import Dialog from "honeyjar/Dialog";
import Button from "./Button";
import ErrorMessageDialog from "./ErrorMessageDialog";
import Router from 'next/router'


interface IError {
    name?: string,
    message?: string
}

interface FormDialog {
    saveLabel?:string,
    errMsg?:string,
    formId?:string,
    onValidate?: () => { 
        errors: IError[],
        payload: any
    },
    onSave:any,
    onDelete?:any,
    onDeleteLabel?:any,
    children?:any,
    title?:any,
    onClose:any,
    loading?:boolean,
    cancelLabel?:string,
    hasCancel?:boolean
}

export default function FormDialog({
    children,
    saveLabel="Save",
    onValidate,
    onSave,
    onDelete,
    onDeleteLabel="Delete",
    errMsg,
    formId="form-dialog",
    cancelLabel="Cancel",
    hasCancel=true,
    ...props
}: FormDialog) {

    const [ loading, setLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");

    const handleSave = useCallback(async (event) => {
        event.preventDefault();

        try {
            let payload;
            let isValid = true;
    
            if(onValidate) {
                let validation = await onValidate();
    
                if(validation?.errors?.length) {
                    isValid = false
                } else {
                    payload = validation.payload;
                }
            }
    
            if(isValid) {
                setLoading(true);

                let response = await onSave(payload);
        
                if(response?.redirect) {
                    setLoading(false);

                    setTimeout(() => {
                        Router.push(response?.redirect);
                    }, 200)
                } else {
                    setLoading(false);

                    props.onClose(response);
                }
            }
        } catch(error) {
            setLoading(false);
            setErrorMessage(errMsg || "Somthing went wrong! Please try again later.")
      
            if(process.env.NODE_ENV === "development") {
              throw new Error(error)
            }
        }
        
    }, [
        onSave,
        onValidate,
        errMsg
    ]);

    const handleDelete = useCallback(() => {
        try {
            setLoading(true);

            var response = onDelete();

            if(response?.redirect) {
                setLoading(false);

                setTimeout(() => {
                    Router.push(response?.redirect);
                }, 200)
            } else {
                setLoading(false);
                props.onClose(response);
            }
        } catch(error) {
            setLoading(false);
            setErrorMessage(errMsg || "Somthing went wrong! Please try again later.")
      
            if(process.env.NODE_ENV === "development") {
              throw new Error(error)
            }
        }

    }, [
        onDelete
    ])

    return (
        <>
            <Dialog
                showClose={ false }
                footer={
                    (
                        <>
                            <div>
                            </div>
                            <div className="flex gap-2 flex-col sm:flex-row w-full justify-end">
                                {
                                    onDelete && false &&
                                    <Button
                                        onClick={ handleDelete }
                                        className="bg-white !text-red-600 hover:!bg-red-600 hover:!text-white">
                                        { onDeleteLabel }
                                    </Button>
                                }
                                {
                                    hasCancel &&
                                    <Button
                                        variant="link"
                                        onClick={ props.onClose }>
                                        { cancelLabel }
                                    </Button>
                                }
                                <Button 
                                    type="submit"
                                    color={ props.status }
                                    form={ formId }>
                                    { saveLabel }
                                </Button>
                            </div>
                        </>
                    )
                }
                loading={ loading }
                {...props}>
                    <form id={ formId } onSubmit={ handleSave }>
                        { children }
                    </form>
            </Dialog>

            {
                errorMessage &&
                <ErrorMessageDialog onClose={ () => setErrorMessage("") }>
                    { errorMessage }
                </ErrorMessageDialog>
            }
        </>
        
    )
}
import FormDialog from "honeyjar/FormDialog";
import MessageDialog from "honeyjar/MessageDialog";

export default function OnlineOrderStoreOfflineDialog(props) {
  let {
    onClose
  } = props;

  return (
    <MessageDialog 
        title="Online Ordering Unavailable"
        onClose={ onClose }>
          <div>
              This store online ordering is currently not available.
          </div>
    </MessageDialog>
  )
}
import {
    useState,
    useEffect
} from "react";

import FormLabel from "honeyjar/FormLabel"

import Icon from '@mdi/react';
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js';

export function RadioInput(props) {
    let {
        id,
        checked
    } = props;

    return (
        <div>
            <input 
                type="radio"
                className="hidden"
                { ...props } />
            <label
                onClick={ event => {
                    event.stopPropagation();
                } } 
                htmlFor={ id } 
                className="cursor-pointer">
                {
                    checked
                    ?
                    <Icon path={mdiRadioboxMarked} size={1} className="text-primary" />
                    :
                    <Icon path={mdiRadioboxBlank} size={1} />
                }
            </label>
        </div>
    )
}

export default function Radio({
    label,
    errors,
    ...props
}: {
    id: string,
    label: any,
    errors?: object[],
    placeholder: string,
    onChange: any
}) {
    return (
        <div className="flex items-center mb-4">
            <RadioInput
                { ...props } />
            <FormLabel
                className="ml-2"
                htmlFor={ props.id }>
                    { label }
            </FormLabel>
        </div>
    )
}
import {
    useContext,
    useState,
    useEffect
} from "react";

import SiteContext from "context/SiteContext";
import formatPrice from "utils/formatPrice";
import countries from "data/countries.json";

export default function Price({
    price,
    currency = "",
    freeLabel="Free",
    ...props
}) {
    const { site } = useContext(SiteContext);

    const [ label, setLabel ] = useState("");

    useEffect(() => {
        let label = freeLabel;

        if(price !== 0) {
            let symbol = countries.find(country => country.currency === (currency || site.currency))?.currency_symbol || "$";
            label = symbol + formatPrice(price);
        }

        setLabel(label);
    }, [
        price,
        currency,
        site.currency,
        freeLabel
    ])

    return (
        <span {...props}>
            { label }
        </span>
    )
}
import { useContext } from "react";
import { getDirectionsFromLocation, getMapsURLFromLocation } from "utils/google/maps";
import LocationAddress from "./LocationAddress";
import Icon from '@mdi/react';
import { mdiStoreMarker, mdiCalendarBlank, mdiAccount, mdiPhone } from '@mdi/js';
import SiteContext from "context/SiteContext";
import React from "react";


export function Detail(props) {
    return (
        <a href={ props.href } target="_blank" className="flex items-center border-2 px-4 py-3 cursor-pointer rounded">
            <div className="mr-4">
                { props.icon }
            </div>
            <div>
                <div>
                    { props.children }
                </div>
                {
                    props.subtitle &&
                    <div className="text-xs text-slate-600">
                        { props.subtitle }
                    </div>
                }
            </div>
        </a>
    )
}


export default function OnlineOrderPickupDetails(props) {
    let {
        location,
        showPhone=false,
        showMap=false,
        showCustomer=false,
        order,
        customer
    } = props;

    const { site } = useContext(SiteContext);

    const createdDate = React.useMemo(() => {
       

        if(order?.created_at) {
            const utcTimeString = order.created_at;
            const timezoneId = site?.properties?.timezone || "America/Vancouver"

            const utcDateTime = new Date(utcTimeString);
            const localDateTime = utcDateTime.toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                hour: 'numeric', 
                minute: 'numeric',
                hour12: true,
                timeZone: timezoneId
            });
    
            return localDateTime;
        }
        
        return ""
      }, [order?.created_at]);

    return (
        <div>
            <div className="text-lg font-bold">
                Pickup details
            </div>
            {
                showMap &&
                <>
                    {
                        process.env.GOOGLE_MAPS_API_KEY && process.env.NODE_ENV !== "development" ?
                        <iframe 
                            name={ `${ site.name } location` }
                            loading="lazy" 
                            className="w-full h-[150px] mt-2"
                            src={ `https://www.google.com/maps/embed/v1/place?key=${ process.env.GOOGLE_MAPS_API_KEY }&q=` + (`${location.address},${location.city},${location.state},${location.country}`) }
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        :
                        <iframe 
                            name={ `${ site.name } location` }
                            loading="lazy" 
                            className="w-full h-[150px] mt-2"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20887.08757527237!2d-123.21124244481324!3d49.12680121969811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54860a75d71b5a85%3A0x3ad6bfef455ca01e!2sMega%20Sushi!5e0!3m2!1sen!2sca!4v1687589951987!5m2!1sen!2sca"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    }
                </>
            }
            
            <div className="flex flex-col gap-y-2 mt-4">
                <Detail
                    href={ getDirectionsFromLocation(location) }
                    subtitle="Get Directions"
                    icon={ <Icon path={mdiStoreMarker} size={1} />}>
                        <LocationAddress
                            location={ location } />
                </Detail>
                {
                    location.phone && showPhone &&
                    <Detail
                        href={ `tel:${location.phone}` }
                        subtitle="Call Restaurant"
                        icon={ <Icon path={mdiPhone} size={1} />}>
                            { location.phone }
                    </Detail>
                }
                {
                    order?.id
                    ?
                    <Detail
                        subtitle="Created Date"
                        icon={ <Icon path={mdiCalendarBlank} size={1} />}>
                            { createdDate }
                    </Detail>
                    :
                    <Detail
                        subtitle="Place order now"
                        icon={ <Icon path={mdiCalendarBlank} size={1} />}>
                            As Soon As Possible
                    </Detail>
                }
                {
                    showCustomer && order?.id &&
                    <Detail
                        subtitle={ `Order #${ order.number }` }
                        icon={ <Icon path={mdiAccount} size={1} />}>
                            { order?.customer?.name } • { order?.customer?.phone }
                    </Detail>
                }
                {
                    customer &&
                    <Detail
                        subtitle={ customer.phone }
                        icon={ <Icon path={mdiAccount} size={1} />}>
                            { customer?.name }
                    </Detail>
                }
            </div>
        </div>
    )
}
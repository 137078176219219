import Icon from '@mdi/react';
import { mdiCartOutline, mdiMapMarkerOutline, mdiStoreClock, mdiPhone  } from '@mdi/js';
import Price from "components/Price";
import useCartTotal from "./useCartTotal";
import Button from "honeyjar/Button";

export function OnlineOrderCartLines(props) {
    let {
        lines,
        onClickLine
    } = props;

    return (
        <div>
            {
                lines.length
                ?
                <>
                    {
                        lines.map((line, lineIndex) => {
                            let finalPrice = Number(line?.product?.price || 0);

                            line?.options?.forEach(option => {
                                option?.choices?.forEach(choice => {
                                    finalPrice += Number(choice.price);
                                })
                            })

                            return (
                                <div
                                    key={ lineIndex } 
                                    className="p-6 border-b cursor-pointer transition-colors hover:bg-slate-200" 
                                    onClick={ () => onClickLine(line) }>
                                    <div className="font-bold">
                                        { line?.product?.name }
                                    </div>
                                    {
                                        line?.options && Object.keys(line?.options)?.length > 0 &&
                                        <div className="text-sm mb-1 text-slate-600">
                                            {
                                                Object.entries(line.options)?.map(([key, option]) => {
                                                    if(!option?.choices?.length) {
                                                        return null;
                                                    }

                                                    return (
                                                        <div key={key}>
                                                            <span className="font-bold mr-1">{option.label}: </span>
                                                            <span>
                                                                {
                                                                    option.choices.map((choice, choiceIndex) => (
                                                                        <span key={ choiceIndex }>
                                                                            {
                                                                                choiceIndex !== 0 &&
                                                                                <>  • </>
                                                                            } { choice.label } 

                                                                            {
                                                                                choice.price > 0 &&
                                                                                <span className="ml-1">
                                                                                    (<Price
                                                                                        currency="+"
                                                                                        freeLabel=""
                                                                                        price={ choice.price } />)
                                                                                </span>
                                                                            }
                                                                            
                                                                            
                                                                        </span>
                                                                    ))
                                                                }
                                                            </span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    <div>
                                        <Price
                                            price={ finalPrice } />
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
                :
                <div className="flex items-center justify-center flex-col py-8">
                    <Icon path={mdiCartOutline} size={ 1.5 } />
                    <div className="mt-4 text-lg">
                        Your cart is empty
                    </div>
                </div>
            }
        </div>
    )
}

export default function OnlineOrderCart(props) {
    let {
        lines,
        onCheckout,
        onClickLine
    } = props;

    const {
        subtotal
    } = useCartTotal(lines)

    return (
        <div className="w-[360px] sticky top-[12px] ml-12 bg-white hidden lg:block border border-slate-300 rounded-lg">
            <div className="px-6 py-4 font-bold border-b border-slate-300">
                Cart { lines.length ? `- ${ lines.length } items` : "" }
            </div>
            <div className="max-h-[70vh] overflow-y-auto">
                <OnlineOrderCartLines
                    lines={ lines }
                    onClickLine={ onClickLine } />
            </div>
            {
                lines.length !== 0 &&
                <div className="p-4">
                    <Button color="green" className="w-full text-lg font-bold py-3 rounded-full"
                        onClick={ onCheckout }>
                        Checkout •
                        <Price
                            className="pl-1"
                           price={ subtotal } />
                    </Button>
                </div>
            }
        </div>
    )
}
import {
    useState,
    useEffect
} from "react";

import FormLabel from "honeyjar/FormLabel"
import Icon from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked  } from '@mdi/js';

export function CheckboxInput(props) {
    let {
        id,
        checked
    } = props;

    return (
        <div>
            <input 
                type="checkbox"
                className="hidden"
                { ...props } />
            <label
                onClick={ event => {
                    event.stopPropagation();
                } } 
                htmlFor={ id } 
                className="cursor-pointer">
                {
                    checked
                    ?
                    <Icon path={mdiCheckboxMarked } size={1} className="text-primary" />
                    :
                    <Icon path={mdiCheckboxBlankOutline} size={1} />
                }
            </label>
        </div>
    )
}

export default function Checkbox({
    label,
    errors,
    ...props
}: {
    id: string,
    label: any,
    errors?: object[],
    placeholder: string,
    onChange: any
}) {
    return (
        <div className="flex items-center mb-4">
            <CheckboxInput
                { ...props } />
            <FormLabel
                className="ml-2"
                htmlFor={ props.id }>
                    { label }
            </FormLabel>
        </div>
    )
}

/*

             <input
                className="w-[16px] h-[16px] text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                type="checkbox"
                {...props} />
*/
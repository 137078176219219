import Price from "components/Price";
import React from "react";

export default function OnlineOrderItemDetails(props) {
    let {
        lines
    } = props;

    return (
        <div>
            <div className="mb-6">
                {
                    lines?.map(line => {

                        let finalPrice = Number(line?.product?.price);
                        
                        line?.options?.forEach(option => {
                            option?.choices?.forEach(choice => {
                                finalPrice += Number(choice.price || 0)
                            })
                        })
                        

                        return (
                            <div key={line.id} className="flex border-b mb-2 px-2 py-4 rounded justify-between">
                                <div className="flex">
                                    {
                                        line.qty &&
                                        <div className="mr-4 w-[26px] h-[26px] bg-slate-300 font-bold flex justify-center">
                                            { line.qty }
                                        </div>
                                    }
                                    
                                    <div>
                                        <div className="font-semibold leading-none">
                                            { line?.product?.name }
                                        </div>
                                        {
                                            line?.options?.length > 0 &&
                                            <div className="text-sm mt-1 text-slate-500">

                                                {
                                                    line?.options?.map((option) => {
                                                        return (
                                                            <div key={ option.id }>
                                                                <span className="font-bold">{ option.label }</span>:
                                                                {
                                                                    option?.choices?.map((choice, choiceIndex) => {
                                                                        return (
                                                                            <React.Fragment key={ choiceIndex }>
                                                                                {
                                                                                    choiceIndex !== 0 &&
                                                                                    <> • </>
                                                                                }
                                                                                { choice.label } (<Price price={ choice.price } />)
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                
                                            </div>
                                        }
                                        
                                    </div>
                                </div>
                                <div className="leading-none">
                                    <Price price={ finalPrice } />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
import {
    useState,
    useEffect
} from "react";

export default function useIsStoreOpen(ownerTimezone, storeHours) {
    const [isStoreOpen, setIsStoreOpen] = useState(true);
  
    useEffect(() => {
        const intervalId = setInterval(() => {
          const ownerCurrentTime = new Date(new Date().toLocaleString('en-US', { timeZone: ownerTimezone }));
          const ownerCurrentHour = Number(`${ownerCurrentTime.getHours().toString().padStart(2, '0')}${ownerCurrentTime.getMinutes().toString().padStart(2, '0')}`)
          
          const currentDay = new Date().getDay();
          const currentDayHours = storeHours?.filter(hour => hour.day === currentDay);
          let isOpen = false;
          
          for (let i = 0; i < currentDayHours?.length; i++) {
            const storeOpen = Number(currentDayHours[i].open) || 2400;
            const storeClose = Number(currentDayHours[i].close) || 2400;

            if (
              (ownerCurrentHour >= storeOpen) &&
              ownerCurrentHour < storeClose
            ) {
              isOpen = true;
              break;
            }
          }
    
          setIsStoreOpen(isOpen);
        }, 1000);
    
        return () => {
          clearInterval(intervalId);
        };
      }, [ownerTimezone, storeHours]);
  
    return isStoreOpen;
};
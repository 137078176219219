export default function OnlineOrderStoreMap(props) {

  let {
    location
  } = props;

  let src = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20887.08757527237!2d-123.21124244481324!3d49.12680121969811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54860a75d71b5a85%3A0x3ad6bfef455ca01e!2sMega%20Sushi!5e0!3m2!1sen!2sca!4v1687589951987!5m2!1sen!2sca";
  if(process.env.GOOGLE_MAPS_API_KEY && process.env.NODE_ENV !== "development") {
    src = `https://www.google.com/maps/embed/v1/place?key=${ process.env.GOOGLE_MAPS_API_KEY }&q=` + (`${location?.address},${location?.city},${location?.state},${location?.country}`)
  }

  return (
    <iframe 
      loading="lazy" 
      className="w-full h-[150px] mt-2"
      src={ src }
      referrerPolicy="no-referrer-when-downgrade" />
)


  /*
{
                        process.env.GOOGLE_MAPS_API_KEY && process.env.NODE_ENV !== "development" ?
                        <iframe 
                            name={ `${ site.name } location` }
                            loading="lazy" 
                            className="w-full h-[150px] mt-2"
                            src={ `https://www.google.com/maps/embed/v1/place?key=${ process.env.GOOGLE_MAPS_API_KEY }&q=` + (`${location.address},${location.city},${location.state},${location.country}`) }
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        :
                        <iframe 
                            name={ `${ site.name } location` }
                            loading="lazy" 
                            className="w-full h-[150px] mt-2"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20887.08757527237!2d-123.21124244481324!3d49.12680121969811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54860a75d71b5a85%3A0x3ad6bfef455ca01e!2sMega%20Sushi!5e0!3m2!1sen!2sca!4v1687589951987!5m2!1sen!2sca"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    }
  */
}
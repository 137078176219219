import {
    useCallback,
    useState
} from "react";

import Dialog from "honeyjar/Dialog";
import Button from "./Button";

export default function MessageDialog({
    children,
    closeLabel="Close",
    ...props
}) {
    return (
        <Dialog
            footer={
                (
                    <>
                        <div>
                        </div>
                        <div>
                            <Button
                                type="submit"
                                form="message-dialog-form"
                                onClick={ props.onClose }>
                                { closeLabel }
                            </Button>
                        </div>
                    </>
                )
            }
            {...props}>
                <form id="message-dialog-form">
                    { children }
                </form>
        </Dialog>
    )
}
import { useContext, useEffect, useState } from "react";
import SiteContext from "context/SiteContext";
import Dialog from "honeyjar/Dialog";
import Button from "honeyjar/Button";
import LocationAddress from "./LocationAddress";
import { getMapsURLFromLocation } from "utils/google/maps";
import OnlineOrderPickupDetails from "./OnlineOrderPickupDetails";
import OnlineOrderItemDetails from "./OnlineOrderItemDetails";
import { OnlineOrderTrackingStatus } from "./OnlineOrderTrackingHeader";
import OnlineOrderCartTotals from "./OnlineOrderCartTotals";
import AppLogo from "components/core/AppLogo";

function TrackingLine({
    isDone,
    isActive
}) {
    
    const [ className, setClassName ] = useState("");

    useEffect(() => {
        let className = "";
        if(isDone) {
            className = "bg-green-700"
        } else if(isActive) {
            className = "bg-green-700 animate-pulse"
        }

        setClassName(className)
    }, [
        isDone,
        isActive
    ])

    return (
        <div className="h-[4px] flex-1 relative bg-slate-200">
            <div className={ className + " w-full h-full absoolute" }></div>
        </div>
    )
}

export default function OnlineOrderTrackingDialog(props) {
    let {
        location,
        order,
        onClose
    } = props;

    let {
        status
    } = order;

    const { site } = useContext(SiteContext);

    return (
        <Dialog 
            title={ `Order #${ order.number }` }
            fullWidth={ true }
            onClose={ onClose }>
            <div>
                <div>
                    <div>
                        <div className="text-2xl font-bold">
                            <OnlineOrderTrackingStatus status={ status } />
                        </div>
                        <div className="flex gap-x-2 my-2">
                            <TrackingLine
                                isDone={ status !== "pending" && status !== "incoming" || status === "cancelled" }
                                isActive={ status === "pending" || status === "incoming" } />
                            <TrackingLine
                                isDone={ status === "ready" || status === "fulfilled" || status === "cancelled" }
                                isActive={ status === "progress" } />
                            <TrackingLine
                                isDone={ status === "fulfilled" || status === "cancelled" }
                                isActive={ status === "ready" || status === "fulfilled"  } />
                        </div>
                    </div>
                    <div className="mt-6">
                        <OnlineOrderPickupDetails
                            showPhone={ true }
                            showMap={ true }
                            showCustomer={ true }
                            order={ order }
                            location={ location } />
                    </div>
                    <div className="mt-8">
                        <div className="text-xl font-bold mb-4">
                            Your Items
                        </div>
                        <div className="border-2 px-4 py-3 rounded">
                            <OnlineOrderItemDetails
                                lines={ order.lines }/>
                            
                            <OnlineOrderCartTotals
                                total={ order.total }
                                taxTotal={ order.taxTotal }
                                subtotal={ order.subtotal } />
                        </div>
                    </div>
                </div>
                
                <div className="text-center py-4 mt-12 flex flex-col items-center">
                    <a href="https://opentummy.ca" target="_blank">
                        <AppLogo />
                    </a>
                    <a href="https://opentummy.ca" target="_blank" className="mt-2 text-rose-700">Powered by Open Tummy</a>
                </div>
            </div>
        </Dialog>
    )
}
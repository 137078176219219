import type { AppProps } from 'next/app'
import { useState, useContext, useEffect } from "react";
import SiteContext from "context/SiteContext";
import OnlineOrder from "components/OnlineOrder/OnlineOrder";
import APIProvider from '@api/APIProvider';
import { useRouter } from 'next/router';

import AppLoading from "components/core/AppLoading"
import AppLogo from 'components/core/AppLogo';
import Link from 'next/link';
import Button from 'honeyjar/Button';

export function AppLink(props) {
  return (
    <Link href="https://opentummy.ca" rel="noopener">
      { props.children }
    </Link>
  )
}

export default function OnlineOrderApp(props: AppProps) {
  const [ loading, setLoading ] = useState(true);
  const [ site, setSite ] = useState({});
  const router = useRouter();
  
  useEffect(() => {
      (async () => {
        // const { domain } = router.query;
        const domain = window?.location?.pathname?.split('/')?.[1];

        if(domain) {
          let site = await APIProvider.getSiteByDomain(String(domain || ""));

          setSite(site);
        }

        setLoading(false);
      })();
  }, [
    router.query
  ]);

  if(loading) {
    return <AppLoading />
  }
  return (
    <SiteContext.Provider value={ { site, setSite } }>
      
      {
        site?.id
        ?
        <OnlineOrder />
        :
        <div className="text-center flex items-center justify-center p-12 h-full fixed w-full">

          <div>

            <div className="text-2xl font-bold mb-4">
              Your Own Restaurant Online Ordering
            </div>
            <div>
              <AppLink>
                <Button>
                  Create Now
                </Button>
              </AppLink>
            </div>
            
            <div className="flex justify-center items-center mt-8">
              <AppLink>
                <AppLogo />
              </AppLink>
              
              <AppLink>
                <div className="font-bold text-lg ml-2">
                  { process.env.APP_NAME }
                </div>
              </AppLink>
            </div>

          </div>

        </div>
      }

      
    </SiteContext.Provider>
  )
}





export default class LocalStorage {
    localStorageKey: string;

    constructor(args) {
        this.localStorageKey = args.key || "__app__";
    }

    getFromLocalStorage() {
        try {
            let siteData = localStorage.getItem(this.localStorageKey);

            if(siteData) {
                return JSON.parse(localStorage.getItem(this.localStorageKey));
            } else {
                return {};
            }
        } catch(e) {
            return {};
        }
    }

    writeToLocalStorage(payload) {
        try {
            localStorage.setItem(this.localStorageKey, JSON.stringify({
                ...payload,
                __cacheAt: new Date().getTime()
            }));
            return true;
        } catch(e) {
            return false;
        }
    }

    unset(key) {
        let localStorage = this.getFromLocalStorage();

        delete localStorage[key];

        this.writeToLocalStorage(localStorage)
    }

    get(key) {
        let localStorage = this.getFromLocalStorage();

        return localStorage[key];
    }

    set(key, payload) {
        let localStorage = this.getFromLocalStorage();
        
        localStorage[key] = payload;

        this.writeToLocalStorage(localStorage)
    }

    clear() {
        this.writeToLocalStorage({})
    }

    export() {
        return this.getFromLocalStorage();
    }

    import(data) {
        this.writeToLocalStorage(data);
    }
}

